import React from 'react';

import { Section, Stack } from 'src/components-v2/Layout';
import { RichTextSwitch } from 'src/containers';

const TextColumn = ({ body, sys, bgColor }) => {
  const isBackgroundColorBlack = bgColor === 'black' || bgColor === 'black1000';
  return (
    <Stack
      className='cms-text-column'
      justifyContent='center'
      sx={{ height: '100%' }}
    >
      <Section
        component='header'
        sx={{
          // we add this code to remove the trailing paragraph added by
          // contentful's rich text editor
          '& p:nth-last-of-type(1):empty': {
            display: 'none',
          },
          '& h3': {
            mb: { xs: '1.6rem', md: '0.8rem' },
          },
          '& p': {
            mb: { xs: '3.2rem', sm: '4rem', lg: '5.6rem' },
          },
          '& p:has(.decorative-text)': {
            // for decorative text
            mb: { xs: '0.8rem', lg: '1.6rem' },
            // for icons
            '& svg': {
              mt: { xs: '-0.2rem', md: '0.6rem' },
              height: { xs: '20px', md: '100%' },
              width: { xs: '20px', md: '24px' },
            },
          },
        }}
      >
        <RichTextSwitch
          sys={sys}
          content={body}
          nodes={{
            h2: {
              variant: 'h3',
              fieldId: 'body',
              sx: {
                color: isBackgroundColorBlack ? 'white' : 'inherit',
              },
            },
            p: {
              variant: 'p',
              fieldId: 'body',
              sx: {
                color: isBackgroundColorBlack ? 'white' : 'inherit',
              },
            },
          }}
        />
      </Section>
    </Stack>
  );
};

export default TextColumn;
